.tableButtons {
  float: right;
}

  .tableButtons .MuiButton-root {
    margin: 5px 5px 5px 0;
  }

.resultContainer {
  padding: 0 5px;
}

.tableContainer {
    margin-top: 5px;
}

.tableHeader {
  font-size: 0.7rem !important;
  color: #4B4B4B !important;
}

.resultLabel {
  margin-right: 5px;
  color: #4B4B4B;
}

.resultValue {
  color: #4B4B4B;
  font-weight: bold;
}

.MuiTableCell-root {
  border-bottom: 1px solid rgba(0,0,0,.12) !important;
}

.MuiDataGrid-cell {
  border-bottom: 1px solid rgba(0,0,0,.12) !important;
}

.cell_notional_amount {
  background: #f5f5f5;
}

.cell_earned_interest_total {
  background: #348CAE;
  color: #FFF !important;
  font-weight: bold !important;
}

.cell_earned_interest_balance {
  background: #00729E;
  color: #FFF !important;
}

.cell_accrued_interest_balance {
  background: #004D6B;
  color: #FFF !important;
}

.summaryOfRates {
  margin-bottom: 0;
  color: #4B4B4B;
  float: left;
  padding: 0 15px;
}
