.smallSpace {
  min-width: 10px;
  display: inline-block;
}

.smallInput {
  width: 80px;
}

.mediumInput {
  width: 100px;
}

.largeInput {
  width: 140px;
}

.largerInput {
  width: 170px;
}

.largestInput {
  width: 200px;
}

.MuiFormControl-root {
  margin: 5px !important;
}

.MuiDivider-root {
  margin: 5px !important;
}

.calculatorInputs .MuiButton-root {
  margin: 9px 5px !important;
}

.inputErrorMessage {
  font-size: 0.7rem;
  color: #f44336;
}

.inputWarningMessage {
  font-size: 0.7rem;
  color: #4B4B4B;
}

.numberSelect .MuiPaper-root {
  max-width: 250px !important;
}

.numberItem {
  display: inline-block !important;
}

.obsShift {
  margin-left: 4px !important;
}
