.toolbar {
  display: flex;
  box-sizing: border-box;
  padding: 0 5px;
  width: 100%;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.headerText {
  white-space: normal;
  line-height: 1.5;
  min-width: 200px;
  margin: 0 5px 5px 0;
}

.headerLinks {
  overflow: hidden;
}

.headerLink {
  font-size: 14px;
  text-align: right;
  margin-left: 30px;
  color: #0366d6;
}

  .headerLink:hover {
    color: #0366d6;
    text-decoration: underline;
    cursor: pointer;
  }

.headerSpacer {
  flex: 1 1 auto;
}

.invisible {
  visibility: hidden;
}

.disclaimer {
  font-size: 0.7rem;
  color: #4B4B4B;
}

.rateDisclaimer {
  padding: 0 5px;
}

.disclaimers {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.toolbarAutoHeight {
  height: auto !important;
}

.headerBanner {
  @media (max-width: 1024px), (orientation: portrait) {
    & {
      flex: 0 1 100%;
    }
  }
}

.releaseTagLine {
  font-weight: bold;
  display: inline-block;
  margin: 0 5px 0 0;
}

.apiErrorMessage {
  font-weight: 700;
  color: #f44336;
}

section {
    margin-bottom: 10px;
}

a {
  color: #00729E;
  text-decoration: none;
  cursor: pointer;
}

body {
  background-color: #fff;
}


.MuiButton-root {
  text-transform: none !important;
}
